.contact {
  padding-top: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleContact {
  display: flex;
  justify-content: center;
  padding-top: 1%;
  margin-bottom: 2vw;
  font-family: "LemonMilk";
  font-size: 4vw;
  color: #00d8feff;
}

.emailContainer {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  margin-bottom: 0;
  width: 100%;
  /* border: 3px solid red; */
}

.inputPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45vw;
}

input {
  height: 2vw;
  -webkit-box-shadow: inset -8px 11px 16px -5px rgba(66, 66, 66, 0.78);
  -moz-box-shadow: inset -8px 11px 16px -5px rgba(66, 66, 66, 0.78);
  box-shadow: inset -8px 11px 16px -5px rgba(66, 66, 66, 0.78);
  background-color: white;
  color: rgba(0, 0, 0, 0.726);
  -webkit-text-stroke-width: 1.05px;
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.726) k;
  font-family: Halva;
  font-size: 1vw;
  margin-bottom: 1vw;
}

.textarea {
  height: 5vw;
  -webkit-box-shadow: inset -8px 11px 16px -5px rgba(66, 66, 66, 0.78);
  -moz-box-shadow: inset -8px 11px 16px -5px rgba(66, 66, 66, 0.78);
  box-shadow: inset -8px 11px 16px -5px rgba(66, 66, 66, 0.78);
  background-color: white;
  color: rgba(0, 0, 0, 0.452);
  -webkit-text-stroke-width: 1.05px;
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.452);
  font-family: Halva;
  font-size: 1vw;
  width: 44.5vw;
  margin-bottom: 1vw;
  margin-top: 1vw;
}

label {
  color: white;
  font-family: Halva;
  margin-bottom: 0.5vw;
  margin-top: 1vw;
}

.sendButton {
  display: flex;
  margin-left: 14vw;
  margin-right: 14vw;
  margin-top: 2vw;
  justify-content: center;
  align-items: center;
  width: 17.5vw;
  height: 4vw;
  border-radius: 10px;
  color: #067bc4;
  font-family: "LemonMilk";
  font-size: 2vw;
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 33%,
    rgba(162, 162, 162, 1) 90%
  );
  box-shadow: none;
  cursor: pointer;
  transition: 425ms;
  -webkit-transition: 425ms;
  -moz-transition: 425ms;
  -ms-transition: 425ms;
  -o-transition: 425ms;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  /* border: 3px solid pink; */
}

.sendButton:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

.iconsC {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  width: 12%;
  height: auto;
  /* border: 2px solid palevioletred; */
  object-fit: contain;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

.iconC {
  padding: 1vw;
  cursor: pointer;
  width: 90%;
  transition: 400ms;
  -webkit-transition: 400ms;
  -moz-transition: 400ms;
  -ms-transition: 400ms;
  -o-transition: 400ms;
  /* border: solid 3px blue; */
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: Halva;
  font-size: 0.6vw;
  margin-bottom: 4vw;
}

.resIcon {
  padding: 0.7vw;
  cursor: pointer;
  width: 1vw;
  transition: 400ms;
  -webkit-transition: 400ms;
  -moz-transition: 400ms;
  -ms-transition: 400ms;
  -o-transition: 400ms;
  /* border: solid 3px blue;
*/
}

.resIcon:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

/* MEDIA QUERIES FOR TABLET VIEW (768PX) */

@media only screen and (max-width: 768px) {
  .titleContact {
    font-size: 7vw;
  }
  .emailContainer {
    margin-bottom: 1vw;
    width: auto;
    height: auto;
    /* border: 3px solid red; */
  }
  .inputPanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    /* border: 3px solid hotpink; */
  }
  input {
    height: 5vw;
    font-size: 3vw;
    margin-bottom: 2.2vw;
  }
  .textarea {
    height: 15vw;
    font-size: 3vw;
    width: auto;
    margin-bottom: 1vw;
    margin-top: 1vw;
  }
  label {
    color: white;
    font-family: Halva;
    margin-bottom: 0.5vw;
    margin-top: 1vw;
  }
  .sendButton {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vw;
    width: 45vw;
    height: auto;
    border-radius: 10px;
    font-size: 5.5vw;
    /* border: 3px solid pink; */
  }
  .iconsC {
    width: 40vw;
    height: 7vw;
    /* border: 2px solid palevioletred; */
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 15vw;
    padding-right: 15vw;
  }
  .iconC {
    width: 7vw;
    /* border: solid 3px blue; */
  }
  footer {
    text-align: center;
    color: white;
    width: 80%;
    font-size: 1.75vw;
    margin-bottom: 10vw;
    margin-top: 1.5vw;
  }
  .resIcon {
    width: 5vw;
    /* border: solid 3px blue; */
  }
}
