.stacks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #000000;
    background-image: linear-gradient(180deg, #000000 0%, #067ac4 21%, #067ac4 78%, #000000 100%);
    /* border: 4px solid salmon; */
}

h2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0.5%;
    font-family: "LemonMilk";
    font-size: 3.25vw;
    color: white;
    width: 100%;
    /* border: 2px solid plum; */
}

.stackCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    /* border: 4px solid orange; */
}

.stackPic {
    width: 80%;
    display: flex;
    margin: 0;
    justify-content: center;
    /* border: 3px solid brown; */
}

.otherCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    /* border: 4px solid orange; */
}

.otherPic {
    width: 80%;
    display: flex;
    margin: 0;
    flex-direction: column;
    justify-content: center;
    /* border: 3px solid brown; */
}

.stackCols {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    width: 90%;
    padding: 6%;
    /* border: 3px solid limegreen; */
}


/* MEDIA QUERIES FOR TABLET VIEW (768PX) */

@media only screen and (max-width: 768px) {
    .stackCols {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        /* margin-left: 20%; */
        width: 100%;
        padding: 0%;
        /* border: 3px solid limegreen; */
    }
    h2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.5%;
        font-family: "LemonMilk";
        font-size: 6.2vw;
        color: white;
        width: 100%;
        /* border: 2px solid plum; */
    }
    .stackCol {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        /* border: 4px solid orange; */
    }
    .stackPic {
        width: 92%;
        display: flex;
        margin: 0;
        justify-content: center;
        /* border: 3px solid brown; */
    }
    .otherCol {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        /* border: 4px solid orange; */
    }
    .otherPic {
        width: 92%;
        display: flex;
        margin: 0;
        justify-content: center;
        margin-right: 5%;
        /* border: 3px solid brown; */
    }
}