.navbar {
  position: fixed;
  height: 50px;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-around;
  transition-timing-function: ease-in;
  transition: all 0.75s;
  -webkit-transition: all 0.75s;
  -moz-transition: all 0.75s;
  -ms-transition: all 0.75s;
  -o-transition: all 0.75s;
  z-index: 1;
}

.nav_black {
  background-color: #111;
}

a {
  font-family: "Halva";
  font-size: 1.2vw;
  margin-top: 0.45%;
  margin-bottom: 0.45%;
  text-decoration: none;
  color: white;
  cursor: pointer;
  transition: 425ms;
  -webkit-transition: 425ms;
  -moz-transition: 425ms;
  -ms-transition: 425ms;
  -o-transition: 425ms;
  margin-right: 13.5px;
}

a:hover {
  transform: scale(1.25);
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -ms-transform: scale(1.25);
  -o-transform: scale(1.25);
}

.orcaLogo {
  width: 3.5vw;
  margin-top: 1.8%;
}

/* MEDIA QUERIES FOR TABLET VIEW (768PX) */

@media only screen and (max-width: 768px) {
  .navbar {
    width: 100%;
    margin-left: 0%;
    justify-content: left;
    align-items: center;
    /* border: 3px white solid; */

  }

  a {
      font-size: 1.7vw;
      padding-left: 3%;
      padding-right: 3%;
  }
  .orcaLogo {
padding-left: 3%;
padding-right: 3%;
margin-top: .75%;
  }

}
