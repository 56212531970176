html {
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  padding: 0;
  display: flex;
  flex-direction: column;
  /*background-position: relative; */
  width: 100%;
  height: 100%;
  background-color: black;
  background-size: cover;
  overflow-x: hidden;
}

@font-face {
  font-family: "LemonMilk";
  src: local("LemonMilk"), url(./fonts/LemonMilk.otf) format("opentype");
}

@font-face {
  font-family: "Halva";
  src: local("Halva"), url(./fonts/HalvaMedReg.otf) format("opentype");
}

.header {
  position: fixed;
}

body {
  margin: 0;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}


/* section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  align-content: right;
} */

span {
  display: inline-block;
  position: absolute;
  justify-content: left;
  padding-left: 2.5%;
  padding-right: 7%;
  padding-top: 4vw;
  padding-bottom: 4vw;
  font-family: "LemonMilk";
  font-size: 4.2vw;
  text-shadow: -4px 6px 8px rgba(0, 0, 0, 0.884);
  /* border: 2px solid pink; */
}

h1 {
  margin: 0;
  padding: 0;
}

h3 {
  margin-top: 0px;
  font-weight: 300;
  font-style: italic;
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
}

.headshot {
  width: 20vw;
  padding-top: 0;
  border-radius: 5%;
  -ms-border-radius: 5%;
  -o-border-radius: 5%;
  -webkit-border-radius: 5%;
  -moz-border-radius: 5%;
}

.orca {
  width: 100%;
  height: 100%;
  z-index: 0;
}

.bioP {
  font-size: 1.4vw;
  line-height: 2.2vw;
  text-align: center;
  width: 90%;
  margin-left: 0;
  padding-left: 0;
  /* border: 3px solid red; */
}

.signature {
  /* border: 4px solid white; */
  max-width: 12vw;
  width: auto;
  height: auto;
}


/* MEDIA QUERIES FOR TABLET VIEW (768PX) */

@media only screen and (max-width: 768px) {
  html {
    margin-top: 0;
    justify-content: center;
    align-items: center;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    background-color: black;
    background-size: cover;
    overflow-x: hidden;
  }
  .orca {
    width: 100%;
    height: auto;
  }
  span {
    display: inline-block;
    position: absolute;
    justify-content: left;
    width: 100%;
    padding-top: 10vw;
    padding-left: 3%;
    font-family: "LemonMilk";
    font-size: 4.2vw;
    text-shadow: -1px 3px 4px rgba(0, 0, 0, 0.733);
    /* border: 2px solid pink; */
  }
  .bioP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4vw;
    line-height: 5.5vw;
    width: 100%;
    margin-left: 0%;
    padding-left: 0;
    margin-right: 0;
    /* border: 3px solid red; */
  }

  .signature {
    /* border: 4px solid white; */
    max-width: 22vw;
    max-height: 17vw;
    height: auto;
    width: auto;
  }
}