.title {
  color: #067bc4;
  font-size: 3.5vw;
}

.devHeader {
  color: rgb(0, 216, 254);
  background-color: black;
  width: 30%;
  font-size: 2.75vw;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: -8px 11px 8px -1px rgba(48, 48, 48, 0.8);
  -moz-box-shadow: -8px 11px 8px -1px rgba(48, 48, 48, 0.8);
  box-shadow: -8px 11px 8px -1px rgba(48, 48, 48, 0.8);
}

.graphHeader {
  color: #067ac4;
  background-color: black;
  width: 30%;
  font-size: 2.75vw;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: -8px 11px 8px -1px rgba(48, 48, 48, 0.8);
  -moz-box-shadow: -8px 11px 8px -1px rgba(48, 48, 48, 0.8);
  box-shadow: -8px 11px 8px -1px rgba(48, 48, 48, 0.8);
}

.cellLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* background-color: white; */
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  /* border: 3px solid hotpink; */
}

.cellRight {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* background-color: white; */
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  /* border: 3px solid lime; */
}

.cellRightG {
  display: flex;
  flex-direction: row-reverse;
  width: 90.5%;
  height: auto;
  align-items: center;
  justify-content: center;
  /* background-color: white; */
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  /* border: 3px solid lime; */
}

.gifLink {
  cursor: pointer;
  transition: 425ms;
  -webkit-transition: 425ms;
  -moz-transition: 425ms;
  -ms-transition: 425ms;
  -o-transition: 425ms;
  margin-right: 13.5px;
}

.gifLink:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

.gifBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25vw;
  margin-top: 0;
  margin-bottom: 0;
  /* border: 3px solid navy; */
}

.gifBoxG {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 25vw;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 10px;
  /*border: 3px solid navy;
  */
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-border-radius: 10px;
}

.gifLinkG {
  cursor: pointer;
  transition: 425ms;
  -webkit-transition: 425ms;
  -moz-transition: 425ms;
  -ms-transition: 425ms;
  -o-transition: 425ms;
  margin-right: 13.5px;
}

.gifLinkG:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

.cellCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3vw;
  width: 90%;
}

.projDescrip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Halva";
  font-size: 1.5vw;
  line-height: 1.7vw;
  color: white;
  /* border: 3px solid crimson; */
  border-radius: 10px;
  margin: 1vw;
  max-width: 60%;
  height: 80%;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

p {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 5vw;
  font-size: 1.2vw;
  line-height: 1.7vw;
  -webkit-text-stroke-width: 0.7px;
  -webkit-text-stroke-color: white;
  margin-right: 5vw;
  margin-bottom: 2vw;
}

.p {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 5vw;
  font-size: 1.2vw;
  line-height: 1.7vw;
  -webkit-text-stroke-width: 0.7px;
  -webkit-text-stroke-color: white;
  margin-right: 5vw;
  margin-bottom: 1vw;
}

.headDes {
  font-family: "Halva";
  color: white;
  -webkit-text-stroke-width: 0;
  -webkit-text-stroke-color: white;
}

.tech {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 5vw;
  font-size: 1.2vw;
  line-height: 2.5vw;
  margin-right: 5vw;
  margin-top: 0.25vw;
  -webkit-text-stroke-width: 1.35px;
  -webkit-text-stroke-color: white;
  margin-right: 5vw;
}

.bold {
  display: flex;
  flex-direction: column;
  font-family: "Halva";
  font-size: 1.5vw;
  line-height: 2vw;
  color: black;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  margin-top: 2vw;
  margin-left: 4.95vw;
  /* border: 2px green solid; */
}

.pTitle {
  padding-bottom: 1.5%;
}

.gif {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.iconWork {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 40px;
  max-height: 40px;
  /* border: 3px solid greenyellow; */
  width: 40px;
  height: 40px;
}

.iconW {
  cursor: pointer;
  max-block-size: 25px;
  color: rgba(0, 0, 0, 0.781);
  transition: 400ms;
  -webkit-transition: 400ms;
  -moz-transition: 400ms;
  -ms-transition: 400ms;
  -o-transition: 400ms;
}

.iconWorkB {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 20px;
  margin-left: 15px;
  color: black;
  /* max-width: 6vw;
  max-height: 6vw; */
  /* border: 3px solid greenyellow; */
}

a.pBlog {
  color: black !important;
  font-size: 10pt;
  padding-bottom: 5px;
}

.gitA {
  color: rgba(0, 0, 0, 0.781);
}

.celeb {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.world {
  margin-top: 5%;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.headerDes {
  font-family: "Halva";
  font-size: 1.2vw;
  color: white;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2vw;
}

.imgBoxA {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 35vw;
  height: auto;
  margin-left: 10vw;
  margin-bottom: 0;
  margin-top: 0.5vw;
}

.imgBoxB {
  display: flex;
  flex-direction: column;
  width: 15vw;
  margin: 1vw;
  padding: 1vw;
  height: auto;
}

.bot {
  width: 15vw;
  padding: 0.5vw;
  background-color: rgba(250, 250, 250, 0.658);
  border-radius: 7px 7px 0px 0px;
  -webkit-border-radius: 7px 7px 0px 0px;
  -moz-border-radius: 7px 7px 0px 0px;
  -ms-border-radius: 7px 7px 0px 0px;
  -o-border-radius: 7px 7px 0px 0px;
}

.bot2 {
  width: 15vw;
  background-color: rgba(0, 0, 0, 0.658);
  padding: 0.5vw;
  border-radius: 0px 0px 7px 7px;
  -webkit-border-radius: 0px 0px 7px 7px;
  -moz-border-radius: 0px 0px 7px 7px;
  -ms-border-radius: 0px 0px 7px 7px;
  -o-border-radius: 0px 0px 7px 7px;
}

.botM {
  height: 15vw;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-box-shadow: -5px 6px 17px -2px rgba(66, 66, 66, 0.78);
  -moz-box-shadow: -5px 6px 17px -2px rgba(66, 66, 66, 0.78);
  box-shadow: -5px 6px 17px -2px rgba(66, 66, 66, 0.78);
}

.words {
  display: flex;
  flex-direction: column;
  font-family: "Halva";
  font-size: 0.8vw;
  color: white;
  /* border: 3px solid crimson; */
  border-radius: 10px;
  margin: 1vw;
  margin-bottom: 4vw;
  margin-left: 13vw;
  margin-right: 2vw;
}

.client {
  flex-direction: column;
  font-family: "Halva";
  font-size: 1.5vw;
  color: black;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  margin-top: 1.5vw;
  margin-left: 5vw;
}

.imgBoxC {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30vw;
  height: auto;
  margin-right: 10vw;
  margin-left: 1vw;
  margin-bottom: 0;
  margin-top: 0;
}

.imgBoxD {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 10vw;
  margin: 1vw;
  margin-bottom: 0;
  padding: 1vw;
  height: auto;
}

.sb1 {
  width: 10vw;
  padding: 0;
  margin: 0.5vw;
  margin-bottom: 0.25vw;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.sbB {
  width: 20vw;
  border-radius: 5px;
  margin-bottom: 5.5vw;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.wordsB {
  display: flex;
  flex-direction: column;
  font-family: "Halva";
  font-size: 0.8vw;
  color: white;
  /* border: 3px solid crimson; */
  border-radius: 10px;
  margin: 1vw;
  margin-bottom: 4vw;
  margin-left: 13vw;
  margin-right: 2vw;
}

.exp {
  background: rgba(0, 216, 254, 1);
  background: -moz-linear-gradient( 153deg, rgba(0, 216, 254, 1) 26%, rgba(6, 122, 196, 1) 90%);
  background: -webkit-linear-gradient( 153deg, rgba(0, 216, 254, 1) 26%, rgba(6, 122, 196, 1) 90%);
  background: linear-gradient( 153deg, rgba(0, 216, 254, 1) 26%, rgba(6, 122, 196, 1) 90%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#067ac4", endColorstr="#00d8fe", GradientType=1);
}


/* MEDIA QUERIES FOR TABLET VIEW (768PX) */

@media only screen and (max-width: 768px) {


  .title {
    font-size: 7vw;
    margin-top: 15%;
  }
  .devHeader {
    font-size: 5vw;
    text-align: center;
    width: 45%;
  }
  .graphHeader {
    width: 55%;
    font-size: 5vw;
  }
  .headerDes {
    font-size: 2.75vw;
    width: 100%;
    text-align: center;
    margin-bottom: 3.5vw;
  }
  .cellLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150%;
  }
  .cellRight {
    display: flex;
    flex-direction: column;
    width: 150%;
  }
  .cellRightG {
    display: flex;
    flex-direction: column;
    width: 150%;
  }
  .gifBox {
    width: 70%;
    margin-left: 2%;
  }
  .gifBoxG {
    width: 67%;
    height: 30%;
    padding-left: 0%;
    background-color: none;
  }
  .projDescrip {
    margin: 3vw;
    margin-bottom: 4vw;
    max-width: 65%;
  }
  .p {
    margin-top: 2vw;
    font-size: 2.75vw;
    line-height: 4vw;
    -webkit-text-stroke-width: 0.3px;
    -webkit-text-stroke-color: white;
  }
  .tech {
    font-size: 2.8vw;
    line-height: 4vw;
  }
  .bold {
    font-size: 3vw;
    line-height: 4vw;
  }
  .pTitle {
    padding-bottom: 3%;
  }
  .iconWork {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    /* max-width: 6vw;
    max-height: 6vw; */
    /* border: 3px solid greenyellow; */
  }



  .iconW {
    padding-left: 9px;
    cursor: pointer;
    max-block-size: 20px;
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .words {
    color: white;
    -webkit-text-stroke-width: 0.3px;
    -webkit-text-stroke-color: white;
    width: 50%;
    margin: 0;
    margin-bottom: 5.5vw;
  }
  .wordsB {
    width: 50%;
    margin: 0;
    margin-bottom: 7.5vw;
  }
  p {
    font-size: 2.75vw;
    line-height: 4vw;
    -webkit-text-stroke-width: 0.3px;
    -webkit-text-stroke-color: white;
  }

  .p {
    color: white;
    -webkit-text-stroke-width: 0.3px;
    -webkit-text-stroke-color: white;
  }
  .client {
    flex-direction: column;
    font-family: "Halva";
    font-size: 3.25vw;
    color: black;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    margin-top: 1.5vw;
    margin-left: 5vw;
  }
  .imgBoxA {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: auto;
    height: auto;
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 0.5vw;
    /* border: 3px solid white; */
  }
  .imgBoxB {
    display: flex;
    flex-direction: column;
    width: auto;
    margin: 1vw;
    margin-right: 0;
    padding: 1vw;
    height: auto;
    /* border: 3px solid pink; */
  }
  .bot {
    width: 45vw;
    background-color: rgba(250, 250, 250, 0.658);
    /* border: 3px solid greenyellow; */
    padding: 0.5vw;
    margin-bottom: 2%;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
  }
  .bot2 {
    width: 45vw;
    /* border: 3px solid greenyellow; */
    background-color: rgba(0, 0, 0, 0.658);
    padding: 0.5vw;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
  }
  .botM {
    height: 56.5vw;
    margin-bottom: 1.25%;
    margin-right: 1.5%;
    margin-left: 0;
  }
  .imgBoxC {
    width: auto;
    height: auto;
    margin: 0;
    /* border: 3px solid orange; */
  }
  .imgBoxD {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    margin: 0;
    margin-bottom: 0;
    padding: 1vw;
    height: auto;
    /* border: 3px solid purple; */
  }
  .sb1 {
    width: 39.5vw;
    padding: 0;
    margin: 0.5vw;
    margin-top: 4vw;
    margin-bottom: 1vw;
    /* border: 3px solid blue; */
  }
  .sbB {
    width: 70vw;
    margin-bottom: 2.25vw;
    /* border: 3px solid gray; */
  }
}