.landingSection {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: flex-start;
  width: 100%;
  flex-wrap: nowrap;
  padding-top: 0;
  margin-bottom: 0;
  /* border: 8px solid yellow; */
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  width: 20%;
  height: auto;
  position: absolute;
  margin-top: 22%;
  margin-left: 72%;
  /* border: 2px solid palevioletred; */
  object-fit: contain;
}

.icon {
  padding: 1.2vw;
  padding-right: 0;
  cursor: pointer;
  width: 90%;
  transition: 400ms;
  -webkit-transition: 400ms;
  -moz-transition: 400ms;
  -ms-transition: 400ms;
  -o-transition: 400ms;
  /* border: solid 3px blue; */
}

.iconR {
  padding: 1vw;
  cursor: pointer;
  width: 110%;
  transition: 400ms;
  -webkit-transition: 400ms;
  -moz-transition: 400ms;
  -ms-transition: 400ms;
  -o-transition: 400ms;
  /* border: solid 3px blue; */
}

.icon:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

.iconR:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

.linkIcon {
  padding-left: 1.8vw;
  cursor: pointer;
  width: 70%;
  transition: 400ms;
  -webkit-transition: 400ms;
  -moz-transition: 400ms;
  -ms-transition: 400ms;
  -o-transition: 400ms;
  /* border: solid 3px blue; */
}

.linkIcon:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

/* MEDIA QUERIES FOR TABLET VIEW (768PX) */

@media only screen and (max-width: 768px) {
  .icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
    width: 80%;
    height: auto;
    position: absolute;
    margin-top: 50vw;
    margin-left: 9%;
    margin-right: 5%;
    /* border: 2px solid palevioletred; */
    object-fit: contain;
  }
  .icon {
    padding: 0vw;
    padding-left: 0;
    cursor: pointer;
    width: 25%;
    margin: 0;
    transition: 300ms;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -ms-transition: 300ms;
    -o-transition: 300ms;
    /*border: solid 3px blue;
    */;
}

  .icon:hover {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
}

  .iconR {
    padding: 0vw;
    padding-left: 0;
    cursor: pointer;
    width: 40%;
    margin: 0;
    transition: 300ms;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -ms-transition: 300ms;
    -o-transition: 300ms;
    /*border: solid 3px blue;
    */;
}

  .iconR:hover {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
}

  .linkIcon {
    padding: 0vw;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    cursor: pointer;
    width: 18%;
    transition: 300ms;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -ms-transition: 300ms;
    -o-transition: 300ms;
    /*border: solid 3px blue;
    */;
}

  .linkIcon:hover {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
}

}
